import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';

// State
import { fetchOrderListIsNeeded } from '../../state/modules/order/actions';
import { twoLastOrdersSelector } from '../../state/modules/order/selectors';
import { totalCountItemsSelector } from '../../state/modules/cart/selectors';
import { addCartItem, addCartItemFromHistory } from '../../state/modules/cart/actions';

// Router
import { paths, getRoute } from '../../entry/routes';

// Components
import Paper from '../Paper';
import Heading from '../Heading';
import OrderItemCompactView from '../OrderItem/CompactView';
import Collapse from '../Collapse';
import ToggleArrow from '../ToggleArrow';
import AppLink from '../AppLink';
import RepeatOrderConflict from '../RepeatOrderConflict';

// Icons
import HistoryIcon from '../../icons/history.svg';

// Styles
import styles from './OrderHistoryWidget.styl';

const cx = classNames.bind(styles);

export default function OrderHistoryWidget(props) {
  const { className, isOpen, toggle } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const cartItemsCount = useSelector(totalCountItemsSelector);
  const list = useSelector(twoLastOrdersSelector);

  const [isModalShown, setIsModalShown] = useState(false);
  const [orderComposition, setOrderComposition] = useState(null);

  const onModalClose = () => {
    setIsModalShown(false);
  };

  const onAddToCart = async composition => {
    const onSuccessCallback = () => {
      dispatch(addCartItemFromHistory());
    };
    for (const item of composition) {
      if (item.discount_price === 0) {
        await dispatch(
          addCartItem({ composition: [{ type: 'good', item: item.item }] }, onSuccessCallback),
        );
      }
    }

    await dispatch(addCartItem({ composition, withoutAnalytic: true }, onSuccessCallback));
    history.push(getRoute(paths.order));
  };

  const onAddToCartWithModal = async composition => {
    if (cartItemsCount) {
      setIsModalShown(true);
      setOrderComposition(composition);
    } else {
      await onAddToCart(composition);
    }
  };

  const onRepeatAddToCart = async () => {
    await onAddToCart(orderComposition);
  };

  useEffect(() => {
    dispatch(fetchOrderListIsNeeded());
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Paper
      className={cx('OrderHistoryWidget', className, {
        OrderHistoryWidget_expended: isOpen,
      })}
    >
      <button className={cx('OrderHistoryWidget__header')} onClick={toggle} type="button" data-test-id='order_history_widget'>
        <div className={cx('OrderHistoryWidget__icon')}>
          <HistoryIcon width={24} height={24} />
        </div>
        <Heading className={cx('OrderHistoryWidget__title')} level={4}>
          <FormattedMessage id="orderHistory.title" />
        </Heading>
        <ToggleArrow className={cx('OrderHistoryWidget__toggle')} isOpen={isOpen} />
      </button>
      <div className={cx('OrderHistoryWidget__content')}>
        <Collapse isOpen={isOpen}>
          {_isEmpty(list) ? (
            <div className={cx('OrderHistoryWidget__list')}>
              <Heading className={cx('OrderHistoryWidget__empty')} level={4} tagName="div">
                <FormattedMessage id="orderHistory.empty" />
              </Heading>
            </div>
          ) : (
            <>
              <div className={cx('OrderHistoryWidget__list')}>
                {list.map(order => (
                  <OrderItemCompactView
                    key={order.id}
                    className={cx('OrderHistoryWidget__item')}
                    addToCart={() => onAddToCartWithModal(order.cart.composition)}
                    order={order}
                    isOpen={isOpen}
                  />
                ))}
              </div>
              <div className={cx('OrderHistoryWidget__footer')}>
                <AppLink to={getRoute(paths.profile)} className={cx('OrderHistoryWidget__link')}>
                  <FormattedMessage id="orderHistory.completeHistory" />
                </AppLink>
              </div>
            </>
          )}
        </Collapse>
      </div>
      <RepeatOrderConflict
        isOpen={isModalShown}
        onClose={onModalClose}
        addToCart={onRepeatAddToCart}
      />
    </Paper>
  );
}

OrderHistoryWidget.defaultProps = {
  className: '',
};

OrderHistoryWidget.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
