import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {connect, useDispatch, useSelector} from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { clearStock } from '../../state/modules/stock/actions';
// Components
import Paper from '../Paper';
import Heading from '../Heading';
import CartItemCompact from '../CartItem/CartItemCompact';
import Amount from '../Amount';
import Button from '../Button';
import AppLink from '../AppLink';
import Preloader from '../Preloader';
import ToggleArrow from '../ToggleArrow';
import Collapse from '../Collapse';
import Form from '../Form';

// Router
import { paths, getRoute } from '../../entry/routes';

// Actions
import * as cartActions from '../../state/modules/cart/actions';
import * as orderActions from '../../state/modules/order/actions';
import * as sailplayActions from '../../state/modules/gifts/actions';
import * as userActions from '../../state/modules/user/actions';
import * as stockActions from '../../state/modules/stock/actions';

// Selectors
import { unusedBonusesSum } from '../../state/modules/user/selectors';
import { totalCountItemsSelector } from '../../state/modules/cart/selectors';
import { priceIncludingBonusesSelector } from '../../state/modules/order/selectors';
import { isAstronautTheme } from "../../state/modules/city/selectors";

// Assetes
import CartIcon from '../../icons/cart.svg';
import promoDecor from '../../assets/FoolDay_images/circus.png';
import cosmoDecor from '../../assets/Astronaut_images/star.svg';

// import ApplePay from './partials/ApplePay';
import ApplePayNew from './partials/ApplePayNew';

import Empty from './Empty';

import styles from './Cart.styl';
import {themeNameSpaces} from "../../state/modules/city/themeNameSpaces";
import PromoArtefact from "../../scenes/Home/PromoGame/PromoArtefact";

const cx = classNames.bind(styles);

const Cart = (props) => {

  const dispatch = useDispatch();
  const {isPromotionGameActive, gameName, artefacts} = useSelector(state => state.promotionGame)
  const isFoolsDayGame = useRef( gameName === themeNameSpaces.FOOLS_DAY).current
  const isAstronaut = useSelector(isAstronautTheme);

  const clear = (e) => {
    e.preventDefault();
    dispatch(clearStock()).then(() => dispatch(props.clearCart()));
  };

  const renderItems = () => (
    <div className={cx('Cart__list')}>
      {props.composition.map(good => (
        <CartItemCompact key={good.hash} good={good} />
      ))}
    </div>
  );

  const {
    composition, totalCount, isLoaded, isOpen, price, priceIncludingBonuses, cash_back,
    applePayExtended, applePay, wantedTimeError, onlinePaySystem, isAuthenticated
  } = props;

  const hasItems = totalCount > 0;
  return (
    <div
      className={cx('Cart', props.className, {
        Cart_opened: isOpen,
        Cart_discount: priceIncludingBonuses && priceIncludingBonuses !== price
      })}
    >
      <div className={cx('Cart__line')}/>
      <div className={cx('Cart__decor')}/>
      {isPromotionGameActive && isFoolsDayGame && (<div className={cx('Cart__promoGame-decor')}>
        <img src={promoDecor}
             className={cx('Cart__promoGame-img')}
             alt="circus"/>

      </div>)}
      <Paper className={cx('Cart__inner')}>
        <div className={cx('Cart__icon')}>
          {isAstronaut && <img src={cosmoDecor}
                               className={cx('Cart__cosmo-decor')}
                               alt="decor"/>}
            <CartIcon width={32} height={32} />
        </div>
        <div className={cx('Cart__header')}>
          <div className={cx('Cart__headline')} data-test-id="cart_header" onClick={props.toggle}>
            <div className={cx('Cart__title')}>
              <Heading className={cx('Cart__heading')} level={3} tagName="div">
                <FormattedMessage id="cart.title" />
              </Heading>{' '}
              {hasItems && isOpen && !props.error && <div className={cx('Cart__count')}>({totalCount})</div>}
            </div>
            <ToggleArrow className={cx('Cart__toggle')} isOpen={isOpen} />
          </div>
          {hasItems && (
            <AppLink href="#" className={cx('Cart__clear')} onClick={clear}>
              <FormattedMessage id="cart.clear" />
            </AppLink>
          )}
        </div>
        <Collapse isOpen={props.isOpen}>
          <div className={cx('Cart__content')} data-test-id="cart_content">
            {props.isLoading && <Preloader />}
            {((isLoaded && _isEmpty(composition)) || props.error) && <Empty />}
            {isLoaded && !_isEmpty(composition) && renderItems()}
          </div>
        </Collapse>
        {hasItems && !props.error && (
          <React.Fragment>
            <div className={cx('Cart__total')} data-test-id="cart_total">
              {!isOpen ? (
                <div className={cx('Cart__total-count')}>
                  <FormattedMessage id="cart.count" values={{ totalCount }} />
                </div>
              ) : (
                <Heading level={4} tagName="div" className={cx('Cart__total-label')}>
                  <FormattedMessage id="cart.totalPrice" />
                </Heading>
              )}
              <Amount
                className={cx('Cart__total-value')}
                value={price}
                newValue={priceIncludingBonuses}
              />
            </div>
            {Boolean(applePayExtended) && applePay && Boolean(onlinePaySystem !== 6) && isAuthenticated && (
              <div className={cx('Cart__pay')}>
                {/* <ApplePay
                    price={priceIncludingBonuses}
                    discount={priceIncludingBonuses - price}
                  /> */}
                <ApplePayNew />
              </div>
            )}
            <div className={cx('Cart__actions')} data-test-id="cart_actions">
              <Button to={getRoute(paths.order)}>
                <FormattedMessage id="button.checkout" />
              </Button>
            </div>
            {cash_back > 0 && (
              <div className={cx('Cart__cashback')} data-test-id="cart_cashback">
                <FormattedMessage id="cart.cashback" values={{ cashback: cash_back }} />
              </div>
            )}
            <Form.Error error={wantedTimeError} />
          </React.Fragment>
        )}
      </Paper>
      {isPromotionGameActive
          && artefacts.find(art => art.id === 'basket')
          && <PromoArtefact activeEffect={"toTop"}
                            id={'basket'}
                            className={cx('Cart__promoArtefact')}
          />
      }
    </div>
  );
};

const connector = connect(
  state => ({
    ...state.cart,
    applePayExtended: _get(state, 'city.userCity.apple_pay_extended', 0),
    onlinePaySystem: _get(
      state,
      'order.checkout.currentRestaurant.pay_params.online_pay_system',
      null
    ),
    applePay: state.payTypes.applePay,
    isAuthenticated: state.user.isAuthenticated,
    totalCount: totalCountItemsSelector(state),
    priceIncludingBonuses: priceIncludingBonusesSelector(state),
    deliveryType: state.order.checkout.deliveryType || '',
    deliveryMinSum: _get(state, 'order.checkout.currentRestaurant.min_sum', ''),
    isPhone: state.responsive.isPhone || false,
    isPayed: state.order.checkout.isPayed,
    wantedTimeError: _get(state, 'order.checkout.error.wanted_time', ''),
    unusedBonusesSum: unusedBonusesSum(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        ...cartActions,
        ...orderActions,
        ...sailplayActions,
        ...userActions,
        ...stockActions
      },
      dispatch
    )
);

export default connector(Cart);
Cart.defaultProps = {
  className: '',
  error: '',
  priceIncludingBonuses: 0
};

Cart.propTypes = {
  className: PropTypes.string,
  isPhone: PropTypes.bool,
  composition: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  clearCart: PropTypes.func.isRequired,
  resetCheckoutPayment: PropTypes.func.isRequired,
  fetchOrderList: PropTypes.func.isRequired,
  setBonuses: PropTypes.func.isRequired,
  clearStock: PropTypes.func.isRequired,
  price: PropTypes.number,
  cash_back: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  error: PropTypes.string,
  priceIncludingBonuses: PropTypes.number,
  applePayExtended: PropTypes.number,
  applePay: PropTypes.bool,
  isPayed: PropTypes.bool,
  unusedBonusesSum: PropTypes.number,
  wantedTimeError: PropTypes.string,
  deliveryType: PropTypes.string,
  deliveryMinSum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onlinePaySystem: PropTypes.number
};
