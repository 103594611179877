import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import classNames from 'classnames/bind';

// Components
import Button from '../../Button';
import OrderPreview from '../../OrderPreview';
import OrderItemDescription from '../../OrderItemDescription';

// Utils
import { isToday } from '../../../utils/helpers';
import parseISO from '../../../utils/parseISO';

// Styles
import styles from './CompactView.styl';
import { getRoute, paths } from '../../../entry/routes';
import AppLink from '../../AppLink';
import Car from '../../../icons/car.svg';

const cx = classNames.bind(styles);

export default function CompactView(props) {
  const {
    className,
    addToCart,
    order: {
      client_number,
      date,
      cart,
      order_status,
      id,
      isOpen,
    },
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const parsedDate = parseISO(date);

  const onAddToCart = async () => {
    setIsLoading(true);
    await addToCart();
    setIsLoading(false);
  };

  const renderStatus = () => {
    switch (Number(order_status)) {
      case 0:
      case 1:
        return (
          <AppLink to={getRoute(paths.orderTrackingAlias, id)} className={cx('OrderItem__status')}>
            <FormattedMessage id="orderHistory.status.taken" />
          </AppLink>
        );
      case 2:
        return (
          <AppLink to={getRoute(paths.orderTrackingAlias, id)} className={cx('OrderItem__status')}>
            <FormattedMessage id="orderHistory.status.prepared" />
          </AppLink>
        );
      case 3:
        return (
          <AppLink to={getRoute(paths.orderTrackingAlias, id)} className={cx('OrderItem__status')}>
            <FormattedMessage id="orderHistory.status.ready" />
          </AppLink>
        );
      case 4:
        return (
          <AppLink to={getRoute(paths.orderTrackingAlias, id)} className={cx('OrderItem__status')}>
            <Car width={24} height={24} />
            <FormattedMessage id="orderHistory.status.inWay" />
          </AppLink>
        );
      case 6:
        return (
          <AppLink to={getRoute(paths.orderTrackingAlias, id)} className={cx('OrderItem__status')}>
            <FormattedMessage id="orderHistory.status.excellent" />
          </AppLink>
        );
      case 5:
      default:
        return (
          <Button isLoading={isLoading} onClick={onAddToCart} small >
            <FormattedMessage id="button.repeat" />
          </Button>
        );
    }
  };

  return (
    <div className={cx('OrderItem', className)}>
      <div className={cx('OrderItem__preview')}>
        <OrderPreview items={cart?.composition} />
      </div>
      <div className={cx('OrderItem__info')}>
        <div className={cx('OrderItem__header')}>
          <div className={cx('OrderItem__date')}>
            {isToday(parsedDate) ? (
              <FormattedMessage id="date.today.label" />
            ) : (
              <FormattedDate value={parsedDate} year="numeric" month="long" day="2-digit" />
            )}
          </div>
          <div className={cx('OrderItem__number')}>№ {client_number}</div>
        </div>
        <div className={cx('OrderItem__description')}>
          <OrderItemDescription composition={cart?.composition} />
        </div>
      </div>
      <div className={cx('OrderItem__actions')}>{renderStatus()}</div>
    </div>
  );
}

CompactView.defaultProps = {
  className: '',
};

CompactView.propTypes = {
  className: PropTypes.string,
  addToCart: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};
