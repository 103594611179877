import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _sortBy from 'lodash/sortBy';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

// Components
import Heading from '../Heading';
import QuantityControl from './partials/QuantityControl';
import Amount from '../Amount';
import VariationInfo from '../VariationInfo';
import AppLink from '../AppLink';
import ToggleArrow from '../ToggleArrow';
import Collapse from '../Collapse';

// Partials
import Sauces from './partials/Sauces';
import AddedSauce from './partials/AddedSauce';
import AddedIngredients from '../AddedIngredients';
import RemovedIngredients from '../RemovedIngredients';
import Gift from './partials/Gift';

// State
import { removeCartItem, updateCartItem } from '../../state/modules/cart/actions';

// Utils
import { isBonus, isGift, isCombobox, isUpsale } from '../../utils/cart';

// Icons
import RemoveIcon from '../../icons/close.svg';

// Styles
import styles from './CartItemCompact.styl';

const cx = classNames.bind(styles);

export default function CartItem(props) {
  const { className, good } = props;
  const {
    count,
    discount_price,
    hash,
    item,
    price,
    // status,
    type,
    old_price,
    upsale_status,
  } = good;
  const {
    // combobox_box_id,
    combo_box_items = [],
    added_ingredients = [],
    added_sauces = [],
    // category,
    // category_id,
    custom_name,
    // diameter,
    // dough,
    free_sauce_count = 0,
    // id,
    image,
    // is_stuffed_crust,
    // kind,
    name,
    removed_ingredients = [],
    sauces = [],
    // size,
    // stuffed_crust,
  } = item;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCompositionOpen, setIsCompositionOpen] = useState(false);

  const isItGift = isGift(good);
  const isItUpsale = isUpsale(good);
  const isItBonus = isBonus(good);
  const isItCombobox = isCombobox(good);

  const toggleComboComposition = e => {
    e.preventDefault();
    setIsCompositionOpen(prev => !prev);
  };

  const changeQuantity = async quantity => {
    setIsUpdating(true);
    await dispatch(
      updateCartItem({
        hash,
        type,
        item,
        sauces: added_sauces.map(sauce => sauce.id),
        count: quantity,
      }),
    );
    setIsUpdating(false);
  };

  const debouncedChangeQuantity = useCallback(
    _debounce(q => changeQuantity(q), 250),
    [added_sauces],
  );

  const onRemoveItem = async () => {
    setIsRemoving(true);
    await dispatch(removeCartItem(hash));
    setIsRemoving(false);
  };

  const onAddFreeSauce = async newId => {
    const includeSauces = added_sauces.map(sauce => sauce.id);

    setIsUpdating(true);
    await dispatch(updateCartItem({ hash, type, item, count, sauces: [newId, ...includeSauces] }));
    setIsUpdating(false);
  };

  const onRemoveFreeSauce = async newId => {
    const removedIdx = added_sauces.findIndex(sauce => sauce.id === newId);
    const saucesIds = added_sauces.filter((_, idx) => idx !== removedIdx).map(sauce => sauce.id);

    setIsUpdating(true);
    await dispatch(updateCartItem({ hash, type, item, count, sauces: saucesIds }));
    setIsUpdating(false);
  };

  return (
    <div
      className={cx('CartItem', className, {
        CartItem_gift: isItGift && discount_price === 0,
      })}
    >
      <div className={cx('CartItem__body')}>
        <figure
          className={cx('CartItem__image', {
            CartItem__image_preview: !image,
            CartItem__image_disabled: upsale_status === false, // он либо не приходит, либо false
          })}
        >
          {image && <img src={image} alt={name} />}
        </figure>
        <div className={cx('CartItem__info')}>
          <Heading level={5} className={cx('CartItem__name')}>
            {custom_name || name}
          </Heading>
          <VariationInfo {...item} className={cx('CartItem__description')} />
          {added_ingredients.length > 0 && <AddedIngredients list={added_ingredients} />}
          {removed_ingredients.length > 0 && <RemovedIngredients list={removed_ingredients} />}
          {added_sauces.length > 0 && (
            <div className={cx('CartItem__sauces')}>
              {added_sauces.map(sauce => (
                <AddedSauce key={sauce.hash} onRemove={onRemoveFreeSauce} sauce={sauce} />
              ))}
            </div>
          )}
          {(free_sauce_count - added_sauces.length) > 0 && (
            <Sauces
              className={cx('CartItem__add-sauce')}
              sauces={sauces}
              addFreeSauce={onAddFreeSauce}
              disabled={isUpdating}
            />
          )}
          {isItCombobox && (
            <>
              <AppLink
                href="#"
                className={cx('CartItem__composition-toggle')}
                onClick={toggleComboComposition}
              >
                {intl.formatMessage({
                  id: isCompositionOpen ? 'cart.composition.rollUp' : 'cart.composition.more',
                })}
                <ToggleArrow isOpen={isCompositionOpen} />
              </AppLink>
              <Collapse isOpen={isCompositionOpen}>
                {_sortBy(combo_box_items, 'category_id').map(comboboxItem => (
                  <div key={comboboxItem.id} className={cx('CartItem__composition-item')}>
                    <div className={cx('CartItem__composition-wrapper')}>
                      <Heading level={5} className={cx('CartItem__name')}>
                        {comboboxItem.custom_name || comboboxItem.name}
                      </Heading>
                      <VariationInfo {...comboboxItem} className={cx('CartItem__description')}/>
                      {comboboxItem.removed_ingredients.length > 0 && (
                        <RemovedIngredients list={comboboxItem.removed_ingredients}/>
                      )}
                    </div>
                    {comboboxItem.count > 1
                      && (<span className={cx('CartItem__composition-count')}>
                        x {comboboxItem.count}
                        </span>)
                    }
                  </div>
                ))}
              </Collapse>
            </>
          )}
        </div>
        {!isItGift && (
          <button
            className={cx('CartItem__remove')}
            onClick={onRemoveItem}
            disabled={isRemoving || isUpdating}
            type="button"
          >
            <RemoveIcon />
          </button>
        )}
      </div>
      {isItGift && <Gift className={cx('CartItem__gift')} count={count} />}
      {(!isItGift || Boolean(discount_price)) && (
        <div className={cx('CartItem__footer')}>
          {!isItGift && (
            <QuantityControl
              quantity={count}
              onChange={debouncedChangeQuantity}
              onRemove={onRemoveItem}
              max={isItUpsale ? 1 : undefined}
            />
          )}
          {Boolean(discount_price) && (
            <Amount
              className={cx('CartItem__amount')}
              value={Math.max(old_price || price, price)}
              newValue={discount_price}
              bonus={isItBonus}
              size="s"
            />
          )}
        </div>
      )}
      {(isUpdating || isRemoving) && (
        <div className={cx('CartItem__loader')}>
          <div className={cx('CartItem__spinner')} />
        </div>
      )}
    </div>
  );
}

CartItem.defaultProps = {
  className: '',
};

CartItem.propTypes = {
  className: PropTypes.string,
  good: PropTypes.object.isRequired,
};
