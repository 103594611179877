import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';

// Components
import Button from '../../../Button';

// Router
import { paths, getRoute } from '../../../../entry/routes';

// Actions
import {
  applePayConfirmation,
  fetchOrderList,
  payOrder,
  resetCheckoutField,
  resetCheckoutPayment,
  sendAnalyticOrderData,
  setCheckoutIsPayed,
  setLocalStorageCheckout,
  applePayConfirmationPGW,
} from '../../../../state/modules/order/actions';
import { clearStock } from '../../../../state/modules/stock/actions';
import { setBonuses } from '../../../../state/modules/user/actions';
import { fetchAddresses } from '../../../../state/modules/address/actions';
import { fetchCards } from '../../../../state/modules/card/actions';
import { resetCartState } from '../../../../state/modules/cart/actions';
import { fetchCatalogUpsale } from '../../../../state/modules/catalog/actions';
import { fetchStopList } from '../../../../state/modules/restaurant/actions';

// Icons
import ApplePayIcon from '../../../../icons/applepay.svg';

// Utils
import isomorphicCookies from '../../../../utils/cookie/isomorphicCookies';
import { setGlobalMessage } from '../../../../utils/setGlobalMessage';

// Selectors
import {
  isOrderSumLowSelector,
  minOrderSumSelector,
  priceIncludingBonusesSelector,
} from '../../../../state/modules/order/selectors';
import { unusedBonusesSum as unusedBonusesSumSelector } from '../../../../state/modules/user/selectors';

// Api
import { post, get } from '../../../../utils/api';

// Styles
import styles from './ApplePayNew.styl';

const cx = classNames.bind(styles);

const ApplePayComponentNew = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { country_code, currency } = useSelector(state => state.city.userCity);
  const priceIncludingBonuses = useSelector(priceIncludingBonusesSelector);
  const { currentRestaurant } = useSelector(state => state.order.checkout);
  const isOrderSumLow = useSelector(isOrderSumLowSelector);
  const minOrderSum = useSelector(minOrderSumSelector);

  // Для ApplePay + Cloudpayment
  const payWithApplePayCloud = () => {
    const session = new ApplePaySession(3, {
      countryCode: country_code,
      currencyCode: currency?.code,
      supportedNetworks: ['masterCard', 'visa'],
      merchantCapabilities: ['supports3DS'],
      total: { label: "Papa John's", amount: priceIncludingBonuses },
    });

    session.onvalidatemerchant = event => {
      post({ url: 'apple-pay/start-session', data: { validation_url: event.validationURL } })
        .then(result => {
          if (result.Success) {
            session.completeMerchantValidation(result.Model);
          }
        })
        .catch(() => {});
    };

    session.onpaymentauthorized = event => {
      const onSuccess = result => {
        if (result.order_id) {
          session.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
        } else {
          session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
          session.abort();
        }
      };

      const payOrderParams = {
        pay_type: 'online',
        cloudpayments_data: { apple_pay_cryptogram: JSON.stringify(event.payment.token) },
      };

      return dispatch(payOrder(payOrderParams, onSuccess));
    };

    session.oncancel = () => {};

    session.begin();
  };

  // Для ApplePay + Sber
  const payWithApplePaySber = () => {
    const session = new ApplePaySession(3, {
      countryCode: country_code,
      currencyCode: currency?.code,
      supportedNetworks: ['masterCard', 'visa'],
      merchantCapabilities: ['supports3DS'],
      total: { label: "Papa John's", amount: priceIncludingBonuses },
    });

    const payOrderParams = {
      pay_type: 'online',
      sberbank_payment_data: { is_apple_pay_web: true },
    };

    const onSuccess = order => {
      session.onvalidatemerchant = event => {
        get({
          url: 'sberbank-payment/apple-pay-validate-merchant',
          data: { validation_url: event.validationURL, order_id: order.order_id },
        })
          .then(result => {
            if (result.success) {
              session.completeMerchantValidation(result.data);
            }
          })
          .catch(() => {});
      };

      session.onpaymentauthorized = event => {
        const onSuccessApplePay = result => {
          if (result.transaction_status) {
            session.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
            dispatch(setCheckoutIsPayed());
          } else {
            session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
            session.abort();
          }
        };

        const confirmationParams = {
          order_id: order.order_id,
          payment_token: JSON.stringify(event.payment.token.paymentData),
        };

        return dispatch(applePayConfirmation(confirmationParams, onSuccessApplePay));
      };
      session.oncancel = () => {};

      session.begin();
    };

    dispatch(payOrder(payOrderParams, onSuccess));
  };

  // Для Apple Pay + Paygateway
  const payWithApplePayPGW = () => {
    let orderId = 0;
    let cartId = 0;
    const session = new ApplePaySession(3, {
      countryCode: country_code,
      currencyCode: currency?.code,
      supportedNetworks: ['masterCard', 'visa'],
      merchantCapabilities: ['supports3DS'],
      total: { label: "Papa John's", amount: priceIncludingBonuses },
    });

    session.onvalidatemerchant = event => {
      const onSuccess = result => {
        if (result?.payment?.apple_pay_session) {
          orderId = result?.order_id;
          cartId = result?.cart_id;
          session.completeMerchantValidation(result.payment.apple_pay_session);
        }
      };

      const payOrderParams = {
        pay_type: 'online',
        pay_gateway_data: {
          apple_pay_validation_url: event.validationURL,
        },
      };

      dispatch(payOrder(payOrderParams, onSuccess));
    };

    session.onpaymentauthorized = event => {
      const onFailApplePay = () => {
        session.completePayment({ status: ApplePaySession.STATUS_FAILURE });
        session.abort();
      };

      const onSuccessApplePay = result => {
        if (result.status) {
          session.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
          dispatch(setCheckoutIsPayed());
        } else {
          onFailApplePay();
        }
      };

      const confirmationParams = {
        order_id: orderId,
        cart_id: cartId,
        apple_pay_cryptogram: JSON.stringify(event.payment.token),
      };

      return dispatch(
        applePayConfirmationPGW(confirmationParams, onSuccessApplePay, onFailApplePay),
      );
    };

    session.oncancel = () => {};
    session.begin();
  };

  const payWithApplePayError = () => {
    if (!currentRestaurant) {
      return setGlobalMessage(intl.formatMessage({ id: 'applePay.chooseAdress' }));
    }

    if (isOrderSumLow) {
      return setGlobalMessage(
        intl.formatMessage(
          { id: 'applePay.minOrder' },
          { currency: currency?.unit, amount: minOrderSum },
        ),
      );
    }

    return null;
  };

  let payWithApplePay = null;

  if (!currentRestaurant || isOrderSumLow) {
    payWithApplePay = payWithApplePayError;
  } else if (currentRestaurant?.pay_params?.online_pay_system === 8) {
    payWithApplePay = payWithApplePaySber;
  } else if (currentRestaurant?.pay_params?.online_pay_system === 7) {
    payWithApplePay = payWithApplePayPGW;
  } else {
    payWithApplePay = payWithApplePayCloud;
  }

  return (
    <div className={cx('ApplePay')}>
      <Button
        className={cx('ApplePay__button')}
        onClick={payWithApplePay}
        label={intl.formatMessage({ id: 'button.payHome' })}
        type="button"
        dark
      >
        {intl.formatMessage({ id: 'button.buyWith' })}
        <ApplePayIcon />
      </Button>
      <OrderEffect />
    </div>
  );
};

function OrderEffect() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { composition } = useSelector(state => state.cart);
  const checkout = useSelector(state => state.order.checkout);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const unusedBonusesSum = useSelector(unusedBonusesSumSelector);

  useEffect(() => {
    return () => {
      dispatch(resetCheckoutField('error'));
    };
  }, []);

  useEffect(() => {
    // при каждом изменении чекаута записываем это в локалстораж
    if (checkout.currentRestaurant) {
      dispatch(setLocalStorageCheckout());
    }
  }, [checkout]);

  useEffect(() => {
    if (checkout.isPayed) {
      // сбрасываем чекаут
      dispatch(resetCheckoutPayment());
      // сбрасываем корзину
      dispatch(resetCartState());
      // сбрасываем промокод
      dispatch(clearStock());
      // надо отправить аналитику что заказ прошел
      dispatch(sendAnalyticOrderData(checkout.order_data));

      if (isAuthenticated) {
        // устанавливаем новое кол-во доступных бонусов
        dispatch(setBonuses(unusedBonusesSum));
        // после завершения заказа должен сохраниться адрес
        dispatch(fetchAddresses());
        // подтягиваем список сохраненных карт, тк во время заказа мы могли ее сохранить
        dispatch(fetchCards());
        // подтягиваем список заказов
        dispatch(fetchOrderList());
      }

      isomorphicCookies().remove('thx_link_closed');
      // для отслеживания заказа у неавторизованного пользователя
      isomorphicCookies().set('last_order_id', checkout.order_id, { expires: 1 });
      // запуск минутного таймера отмены для определенного заказа
      isomorphicCookies().set('cancel_timer', `${checkout.order_id}|${new Date().getTime()}`, {
        expires: 1,
      });
      // Закидываем на thankyoupage
      history.push(`${getRoute(paths.orderTrackingAlias, checkout.order_id)}`);
    }
  }, [checkout.isPayed]);

  useEffect(() => {
    // запрашиваем список товаров в апсейл. переспрашиваем при изменении в корзине
    // composition меняется всегда при запросах на корзину, даже если товары внутри не поменялись
    // тк приходит новая ссылка на объект
    if (!_isEmpty(composition)) {
      dispatch(fetchCatalogUpsale());

      //  надо чекнуть, есть ли этот товар в стоплисте ресторана
      if (checkout.currentRestaurant) {
        dispatch(fetchStopList(checkout.currentRestaurant.id));
      }
    }
  }, [composition]);

  return null;
}

export default ApplePayComponentNew;
